"use client";

import { AvatarProps } from "@radix-ui/react-avatar";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Profile } from "@/lib/db/types";
import { cn } from "@/lib/utils";
import { State } from "@/lib/state";
import ClientRender from "../helpers/client-render";

interface UserAvatarProps extends AvatarProps {
  user: Pick<Profile, "image" | "name" | "id">;
  size?: "default" | "sm";
  className?: string;
}

export function UserAvatar({
  user,
  size = "default",
  className,
  ...props
}: UserAvatarProps) {
  const onlineUsers = State.onlineUsersIds.use();

  return (
    <ClientRender>
      <div className="relative flex h-fit w-fit">
        <Avatar className={cn(size === "sm" && "h-5 w-5 text-xs", className)} {...props}>
          {user.image ? (
            <AvatarImage alt="Picture" src={user.image} />
          ) : (
            <AvatarFallback>
              <span className="sr-only">{user.name}</span>
              {user.name
                .split(" ")
                .slice(0, 2) 
                .map((word: string) => word.charAt(0).toUpperCase())
                .join("")}
            </AvatarFallback>
          )}
        </Avatar>
        <div
          className={cn(
            "absolute bottom-0 right-0 rounded-full ring-1 ring-background",
            size === "sm" && "h-1.5 w-1.5",
            size === "default" && "h-2 w-2",
            onlineUsers.includes(user.id) ? "bg-emerald-400" : "bg-gray-400"
          )}
        ></div>
      </div>
    </ClientRender>
  );
}
