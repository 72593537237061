'use client';

import { useState, useRef } from 'react';
import {
  KnockFeedProvider,
  NotificationFeedPopover,
  useKnockFeed,
} from '@knocklabs/react-notification-feed';

import '@knocklabs/react-notification-feed/dist/index.css';
import { Profile } from '@/lib/db/types';
import { Bell } from 'lucide-react';
import { Button } from '../ui/button';

export const KnockFeed = ({ user }: { user: Profile }) => {
  return (
    <div>
      <KnockFeedProvider
        apiKey={process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY!}
        feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID!}
        userId={user.id}
      >
        <FeedComponent />
      </KnockFeedProvider>
    </div>
  );
};

const FeedComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const { useFeedStore } = useKnockFeed();
  const metadata = useFeedStore((state) => state.metadata);

  let unreadCount: string | null = null;
  if (metadata && metadata.unread_count > 0) {
    unreadCount =
      metadata.unread_count > 9 ? '9+' : String(metadata.unread_count);
  }

  return (
    <>
      <div className='relative'>
        <Button
          className='text-secondary-foreground/80 hover:bg-card/10 hover:text-secondary-foreground'
          ref={notifButtonRef}
          onClick={() => setIsVisible(!isVisible)}
          variant={'ghost'}
          size={'icon'}
        >
          <Bell />
        </Button>
        {unreadCount && (
          <div className='absolute right-0 top-0 inline-flex h-4 w-4 items-center justify-center rounded-full bg-destructive'>
            <p className='text-xs font-medium text-destructive-foreground'>
              {unreadCount}
            </p>
          </div>
        )}
      </div>

      <NotificationFeedPopover
        buttonRef={notifButtonRef}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};
