"use client";

import * as React from "react";
import { X } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Command, CommandGroup, CommandItem } from "@/components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import { FormControl, FormDescription, FormItem, FormLabel, FormMessage } from "./ui/form";

type Option<T> = {
  value: string;
  label: string;
  data: T;
};
type Props<T> = {
  name: string;
  options: Option<T>[];
  defaultValue?: Option<T>[];
  description?: string;
  onChange?: (selectedValues: string[]) => void; // Added
};

export function MultiSelect<T>({
  name,
  options,
  defaultValue = [],
  description,
  onChange,
}: Props<T>) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<Option<T>[]>(defaultValue);
  const [selectables, setSelectables] = React.useState<Option<T>[]>(options);
  const [inputValue, setInputValue] = React.useState("");

  const handleUnselect = React.useCallback((option: Option<T>) => {
    setSelected((prev) => {
      const newSelected = prev.filter((s) => s.value !== option.value);
      if (onChange) {
        onChange(newSelected.map(o => o.value)); // Notify the parent form
      }
      return newSelected;
    });
  }, [onChange]);

  const handleSelect = (option: Option<T>) => {
    setSelected((prev) => {
      const newSelected = [...prev, option];
      if (onChange) {
        onChange(newSelected.map(o => o.value)); // Notify the parent form
      }
      return newSelected;
    });
  };

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === "Delete" || e.key === "Backspace") {
          if (input.value === "") {
            setSelected((prev) => {
              const newSelected = [...prev];
              newSelected.pop();
              return newSelected;
            });
          }
        }
        if (e.key === "Escape") {
          input.blur();
        }
      }
    },
    []
  );

  
  React.useEffect(() => {
    setSelectables(() => {
      const newSelectables = options.filter(
        (o) => !selected.find((s) => s.value === o.value)
      );
      return newSelectables;
    });
  }, [selected, options]);

  

  return (
    <FormItem>
      <FormLabel>{name}</FormLabel>
      <FormControl>
        <Command
          onKeyDown={handleKeyDown}
          className="overflow-visible bg-transparent"
        >
          <div className="flex h-fit w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
            <div className="flex flex-wrap gap-1">
              {selected.map((framework) => {
                return (
                  <Badge key={framework.value} variant="secondary">
                    {framework.label}
                    <button
                    type='button'
                      className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleUnselect(framework);
                        }
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => handleUnselect(framework)}
                    >
                      <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                    </button>
                  </Badge>
                );
              })}
              {/* Avoid having the "Search" Icon */}
              <CommandPrimitive.Input
                ref={inputRef}
                value={inputValue}
                onValueChange={setInputValue}
                onBlur={() => setOpen(false)}
                onFocus={() => setOpen(true)}
                placeholder={`Search and select ${name}...`}
                className="flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
              />
            </div>
          </div>
          <div className="relative">
            {open && selectables.length > 0 ? (
              <div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                <CommandGroup className="max-h-60 overflow-auto">
                  {selectables.map((option) => {
                    return (
                      <CommandItem
                        key={option.value}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onSelect={() => {
                          setInputValue("");
                          handleSelect(option);
                        }}
                        className={"cursor-pointer"}
                      >
                        {option.label}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </div>
            ) : null}
          </div>
        </Command>
      </FormControl>
      <FormDescription>
        {description}
      </FormDescription>
      <FormMessage />
    </FormItem>
  );
}
