"use client"


import { handleSignOut } from "../auth/utils/handle-sign-out";
import { useState } from "react";
import { useRouter } from "next/navigation";
import { toast } from "@/components/ui/use-toast"
import { LogOut } from "lucide-react";
import { DropdownMenuItem } from "../ui/dropdown-menu";

export function UserNavLogoutItem() {
  const [, setIsLoading] = useState<boolean>(false);
  const router = useRouter();

  
  return (
    <DropdownMenuItem
      onSelect={async () => {
        await handleSignOut({
          setIsLoading,
          router,
          toast,
        });
      }}
    >
      <LogOut className="mr-2 h-4 w-4" />
      <span>Log out</span>
    </DropdownMenuItem>
  );
}
