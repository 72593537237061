import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "../ui/use-toast";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useEffect } from "react";
import { createNewPractice, getGroups } from "@/server";
import { useRouter } from "next/navigation";
import { Icons } from "../icons";
import { MultiSelect } from "../multi-select";
import { useServerAction } from "@/lib/utils/use-server-action";
import { Skeleton } from "../ui/skeleton";
import React from "react";

const practiceFormSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
  slug: z
    .string()
    .min(2, {
      message: "Slug must be at least 2 characters.",
    })
    .regex(/^[a-z]+(?:-[a-z]+)*$/, {
      message: "Slug must be all lowercase and contain no spaces or numbers.",
    }),
  groups: z.array(z.string()).optional(),
});
type PracticeFormValues = z.infer<typeof practiceFormSchema>;

export const NewPracticeDialog = ({
  setShowNewPracticeDialog,
}: {
  setShowNewPracticeDialog: (show: boolean) => void;
}) => {
  const [groupOptions, setGroupOptions] = React.useState<
    { label: string; value: string; data: any }[]
  >([]);
  const form = useForm<PracticeFormValues>({
    resolver: zodResolver(practiceFormSchema),
  });

  const { data: groups, loading, execute } = useServerAction(getGroups);

  useEffect(() => {
    execute();
  }, []);

  useEffect(() => {
    if (groups) {
      setGroupOptions(
        groups.map((group) => ({
          label: group.name,
          value: group.id.toString(),
          data: group,
        }))
      );
    }
  }, [groups]);
  const router = useRouter();

  const { watch, setValue, formState } = form;

  const name = watch("name");

  useEffect(() => {
    if (name) {
      const slug = name
        .toLowerCase()
        .replace(/[^a-z]+/g, "-")
        .replace(/(^-|-$)/g, "");
      setValue("slug", slug);
    }
  }, [name, setValue]);

  async function onSubmit(data: PracticeFormValues) {
    try {
      const practice = await createNewPractice(data.name, data.slug, data.groups);
      setShowNewPracticeDialog(false);
      router.push(`/${practice.slug}`);
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: "We weren't able to create a new practice right now. Does this practice already exist?",
        variant: "destructive",
      });
    }
    form.reset();
  }

  return (
    <DialogContent>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle>Create practice</DialogTitle>
            <DialogDescription>
              Add a new practice for advisers to join.
            </DialogDescription>
          </DialogHeader>
          <div>
            <div className="space-y-4 py-2 pb-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Example Practice" {...field} />
                    </FormControl>
                    <FormDescription>
                      This is the name that will be displayed in the system.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="slug"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>URL</FormLabel>
                    <FormControl>
                      <Input placeholder="example-practice" {...field} />
                    </FormControl>
                    <FormDescription>
                      This will be used in the practices homepage.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {loading ? (
                <Skeleton className="h-10 w-40" />
              ) : (
                <FormField
                  control={form.control}
                  name="groups"
                  render={({ field }) => (
                    <MultiSelect
                      options={groupOptions}
                      {...field}
                      description="Select the paraplanner groups that will have access to this practice."
                    />
                  )}
                />
              )}
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowNewPracticeDialog(false)}
            >
              Cancel
            </Button>
            <Button disabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting && (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};
