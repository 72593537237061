import supabase from '@/lib/supabase.client';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { Dispatch, SetStateAction } from 'react';

interface HandleSignOutArgs {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  router: AppRouterInstance;
  toast: any;
}

export const handleSignOut = async ({
  setIsLoading,
  toast,
  router
}: HandleSignOutArgs) => {
  setIsLoading(true);
  try {
    const { error } = await supabase.auth.signOut();

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    toast({
      title: 'Error',
      description: error,
      variant: 'destructive'
    });
  } finally {
    setIsLoading(false);
    router.push('/');
    router.refresh();
  }
};
