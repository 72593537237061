"use client";

import { useEffect } from "react";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { enableReactUse } from "@legendapp/state/config/enableReactUse";
import { State } from "@/lib/state";
import { useServerAction } from "../helpers/use-server-action";
import { getCurrentUser } from "@/server";

export const OnlineUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const supabaseClient = createClientComponentClient();

  enableReactUse();

  const { data: user, execute } = useServerAction(getCurrentUser);

  useEffect(() => {
    execute();
  }, []);

  useEffect(() => {
    if (!user) return;
    const channel = supabaseClient.channel("online-users", {
      config: {
        presence: {
          key: user.id,
        },
      },
    });

    channel.on("presence", { event: "sync" }, () => {
      const presentState = channel.presenceState();
      State.onlineUsersIds.set(Object.keys(presentState));
    });

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        await channel.track({
          user_id: user.id,
        });
      }
    });

    return () => {
      channel.unsubscribe();
    };
  }, [user]);

  return <>{children}</>;
};
