'use client';

import { ProfileRole } from '@/lib/db/custom-types';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { useSelectedLayoutSegments } from 'next/navigation';

export default function TopNav({ role }: { role: ProfileRole }) {
  const segments = useSelectedLayoutSegments();

  if (isInPractice(segments)) {
    const navigation = practiceNavItems(segments, role);

    return (
      <div className="ml-10 flex items-baseline space-x-4">
        {navigation.map((item) => (
          <Link
            key={item.name}
            // @ts-ignore
            href={item.href}
            className={cn(
              item.current
                ? 'bg-gray-900 text-white dark:bg-card-foreground/10'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white dark:hover:bg-gray-900',
              'rounded-md px-3 py-2 text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </Link>
        ))}
      </div>
    );
  }

  return null;
}

const isInPractice = (segments: string[]) => {
  // when second segment is 'home' or 'advice'
  return segments[1] === 'home' || segments[1] === 'advice';
};

const practiceNavItems = (segments: string[], role: ProfileRole) => {
  const slug = segments[0];
  const items = [
    {
      name: 'Requests',
      href: `/${slug}/home/requests`,
      current: segments[2] === 'requests',
    },
    {
      name: 'Users',
      href: `/${slug}/home/users`,
      current: segments[2] === 'users',
    },
  ];
  role !== 'paraplanner' &&
    items.push({
      name: 'Groups',
      href: `/${slug}/home/groups`,
      current: segments[2] === 'groups',
    });
  return items;
};
