import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Converts a given date to the client's timezone. Only use client side.
 * @param date - Date object or string to convert.
 * @returns The converted date in the client's timezone as a dayjs object.
 */
export const toClientTZ = (date: Date | string): dayjs.Dayjs => {
  const userTimeZone = dayjs.tz.guess();
  return dayjs.utc(date).tz(userTimeZone);
};

/**
 * Converts a client side date to UTC. Only use client side.
 * @param date - Date object or string created client side
 * @returns The converted date in UTC timezone as a dayjs object.
 */
export const toUTC = (date: Date | string): dayjs.Dayjs => {
  return dayjs(date).utc();
};

/**
 * @deprecated This function is deprecated. Please use toClientTZ instead.
 */
export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

export function toSydneyTZ(input: Date | string | number) {
  return dayjs.utc(input).tz('Australia/Sydney').format('YYYY-MMM-DD-HH:mm');
}
