import(/* webpackMode: "eager", webpackExports: ["OnlineUserProvider"] */ "/vercel/path0/components/avatar/online-users-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserAvatar"] */ "/vercel/path0/components/avatar/user-avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/vercel/path0/components/mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KnockFeed"] */ "/vercel/path0/components/navbar/knock-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/practice-switcher-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/top-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserNavLogoutItem"] */ "/vercel/path0/components/navbar/user-nav-logout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuGroup","DropdownMenuItem"] */ "/vercel/path0/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-axiom@1.1.1_next@14.2.4_@babel+core@7.23.3_babel-plugin-macros@3.1.0_react-dom@18.2.0_re_okloyx44w22zf4t3g4dkpze6ea/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
