import { ProfileRole } from './db/custom-types';

type Route = {
  name: string;
  path: string;
  protected: boolean;
  navbar: boolean;
  subRoutes: string[];
  allowed_roles?: ProfileRole[];
};

type Routes = {
  cat: Route;
  login: Route;
  review: Route;
  new: Route;
  profile: Route;
  advice: Route;
  archived: Route;
  magic_login: Route;
  mfa: Route;
  mfa_setup: Route;
};

export const routes: Routes = {
  cat: {
    name: 'Central Advice Team',
    path: '/cat',
    protected: true,
    navbar: true,
    subRoutes: ['/archived'],
  },
  login: {
    name: 'Login',
    path: '/',
    protected: false,
    navbar: false,
    subRoutes: [],
  },
  review: {
    name: 'SOA',
    path: '/review',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  new: {
    name: 'New Review',
    path: '/admin/reviewer/new',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  profile: {
    name: 'Profile',
    path: '/me',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  advice: {
    name: 'Advice',
    path: '/advice',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  archived: {
    name: 'Archived',
    path: '/cat/archived',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  magic_login: {
    name: 'Magic Login',
    path: '/auth/magic',
    protected: false,
    navbar: false,
    subRoutes: [],
  },
  mfa_setup: {
    name: 'Setup MFA',
    path: '/mfa-setup',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
  mfa: {
    name: 'MFA',
    path: '/mfa',
    protected: true,
    navbar: false,
    subRoutes: [],
  },
};

export const admin_routes = {
  users: {
    name: 'Users',
    path: '/admin/users',
    protected: true,
    navbar: true,
    icon: 'user',
    disabled: false,
    subRoutes: [],
  },
  rules: {
    name: 'Rules',
    path: '/admin/rules',
    protected: true,
    navbar: true,
    icon: 'split',
    disabled: false,
    subRoutes: [],
  },
  groups: {
    name: 'Groups',
    path: '/admin/groups',
    protected: true,
    navbar: true,
    icon: 'boxes',
    disabled: false,
  },
  reviewer: {
    name: 'Reviewer',
    path: '/admin/reviewer',
    protected: true,
    navbar: true,
    icon: 'view',
    disabled: false,
    subRoutes: ['/new'],
  },
  writer: {
    name: 'Writer',
    path: '/admin/writer',
    protected: true,
    navbar: true,
    icon: 'pencil',
    disabled: true,
  },
  reports: {
    name: 'Reports',
    path: '/admin/reports',
    protected: true,
    navbar: true,
    icon: 'file-text',
    disabled: false,
    subRoutes: [],
  },
};

export const practice_routes = {
  home: {
    name: 'Home',
    path: `/home`,
    protected: true,
    navbar: false,
    icon: 'home',
    disabled: false,
    subRoutes: ['/new'],
  },
  requests: {
    name: 'Requests',
    path: `/home/requests`,
    protected: true,
    navbar: true,
    icon: 'post',
    disabled: false,
    subRoutes: ['/new'],
  },
  users: {
    name: 'Users',
    path: `/home/users`,
    protected: true,
    navbar: true,
    icon: 'user',
    disabled: false,
    subRoutes: [],
  },
  groups: {
    name: 'Groups',
    path: `/home/groups`,
    protected: true,
    navbar: true,
    icon: 'boxes',
    disabled: false,
    subRoutes: [],
  },
};

export const group_routes = {
  home: {
    name: 'Home',
    path: `/`,
    protected: true,
    navbar: false,
    icon: 'home',
    disabled: false,
    subRoutes: ['/new'],
  },
  requests: {
    name: 'Requests',
    path: `/requests`,
    protected: true,
    navbar: true,
    icon: 'post',
    disabled: false,
    subRoutes: ['/new'],
  },
  users: {
    name: 'Paraplanners',
    path: `/users`,
    protected: true,
    navbar: true,
    icon: 'user',
    disabled: false,
    subRoutes: [],
  },
};
