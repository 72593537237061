import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Profile } from "../db/types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const titleCase = (str: string) => {
  return str
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const toSentenceCase = (str: string) => {
  return str
    .split("_")
    .map((word) => {
      if (
        word === "cat" ||
        word === "ipr" ||
        word == "soa" ||
        word == "fsg" ||
        word === "aml" ||
        word === "crm" ||
        word === "rpq" ||
        word === "ina" ||
        word === "ctf" ||
        word === "fna"
      ) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");
};

export const canAccessAI = (user: Profile) => {
  switch (true) {
    case user.email == "jack.standing@wtfglimited.com":
      return true;
    case user.email == "frank.paul@wtfglimited.com":
      return true;
    case user.email == "connor+adviser@knkt.com.au":
      return false;
    case user.email == "max.song@wtfglimited.com":
      return true;
    case user.email == "samuel.chen@wtfglimited.com":
      return true;
    case user.email.split("@").pop() == "knkt.com.au":
      return true;
    default:
      return false;
  }
};

export * from "./dates";
