// state.ts
import { observable } from "@legendapp/state"
import { Check } from './db/types';

interface StateType {
  onlineUsersIds: string[];
  currentUserId: string;
  selectedCheck: Check | undefined;
  selectedCheckId: string | undefined;
}

export const State = observable<StateType>({
  onlineUsersIds: [''],
  currentUserId: '',
  selectedCheck: undefined,
  selectedCheckId: undefined,
});
