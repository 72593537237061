"use client";

import React from "react";

type ClientRenderProps = {
  children: React.ReactNode;
};

export default function ClientRender({ children }: ClientRenderProps) {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? children : null;
}
